import { Routes, Navigate, Route } from "react-router-dom";
import "./App.css";
import MyApiComponent from "./components/MyApiComponent";
import AddingNewData from "./components/AddingNewData";
import EditExistData from "./components/EditExistData";
import LoginPage from "./components/LoginPage";
import Layout from "./components/Layout";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/auth" element={<LoginPage />} />
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Navigate to="home" />} />
          <Route path="/home" element={<MyApiComponent />} exact />
          <Route path="/add" element={<AddingNewData />} />
          <Route path="/edit/:id" element={<EditExistData />} />
          <Route path="*" element={<>Page Not Found</>} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
