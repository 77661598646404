import ky from "ky";
import { useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "./AddingNewData.css";

const EditExistData = (props) => {
  const apiUrl = "https://6426d11d556bad2a5b58c7dc.mockapi.io/MealsApi/users";
  const params = useParams();
  const { state } = useLocation();
  const { filtered } = state;
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [state1, setState] = useState(filtered);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let res = await ky.put(`${apiUrl}/${params.id}`, {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify({
          name: state1.name,
          username: state1.username,
          email: state1.email,
          phone: state1.phone,
          companiesId: state1.companiesId,
          password: state1.password,
        }),
      });

      if (res.status === 200) {
        navigate("/home");
      } else {
        setMessage(`Some error occured ${res.status}`);
      }
    } catch (err) {
      console.log(err);
    }
  };

  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...state1,
      [evt.target.name]: value,
    });
  }

  return (
    <div className="expenses">
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input
            type="text"
            name="name"
            value={state1.name}
            onChange={handleChange}
          />
        </label>
        <label>
          UserName
          <input
            type="text"
            name="username"
            value={state1.username}
            onChange={handleChange}
          />
        </label>
        <label>
          Phone
          <input
            type="text"
            name="phone"
            value={state1.phone}
            onChange={handleChange}
          />
        </label>
        <label>
          Email
          <input
            type="text"
            name="email"
            value={state1.email}
            onChange={handleChange}
          />
        </label>
        <label>
          Company Id
          <input
            type="text"
            name="companiesId"
            value={state1.companiesId}
            onChange={handleChange}
          />
        </label>
        <label>
          Password
          <input
            type="text"
            name="password"
            value={state1.password}
            onChange={handleChange}
          />{" "}
        </label>
        <input type="submit" value="Update" />
      </form>
      <div className="message">{message ? <p>{message}</p> : null}</div>
    </div>
  );
};

export default EditExistData;
