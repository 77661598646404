import ky from "ky";
import { useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";

import "./AddingNewData.css";

const AddingNewData = () => {
  const apiUrl = "https://6426d11d556bad2a5b58c7dc.mockapi.io/MealsApi/users";
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [state, setState] = useState({
    name: "",
    username: "",
    email: "",
    phone: "",
    companiesId: "",
    password: "",
  });
  const isAuth = localStorage.getItem("isAuth");
  let handlerSubmit = async (e) => {
    e.preventDefault();
    try {
      let res = await ky.post(apiUrl, {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify({
          name: state.name,
          username: state.username,
          email: state.email,
          phone: state.phone,
          companiesId: state.companiesId,
          password: state.password,
        }),
      });
      if (res.status === 201) {
        navigate("/home");
      } else {
        setMessage(`Some error occured ${res.status}`);
      }
    } catch (err) {
      console.log(err);
    }
  };

  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  if (!isAuth) {
    return <Navigate replace to="/auth" />;
  } else {
    return (
      <div className="expenses">
        <form onSubmit={handlerSubmit}>
          <label>
            Name
            <input
              required
              type="text"
              name="name"
              value={state.name}
              onChange={handleChange}
            />
          </label>
          <label>
            UserName
            <input
              required
              type="text"
              name="username"
              value={state.username}
              onChange={handleChange}
            />
          </label>
          <label>
            Phone
            <input
              required
              type="text"
              name="phone"
              value={state.phone}
              onChange={handleChange}
            />
          </label>
          <label>
            Email
            <input
              required
              type="text"
              name="email"
              value={state.email}
              onChange={handleChange}
            />
          </label>
          <label>
            Company Id
            <input
              required
              type="text"
              name="companiesId"
              value={state.companiesId}
              onChange={handleChange}
            />
          </label>
          <label>
            Password
            <input
              required
              type="text"
              name="password"
              value={state.password}
              onChange={handleChange}
            />
          </label>
          <input type="submit" value="Submit" />
        </form>
        <div>{message ? <p>{message}</p> : null}</div>
      </div>
    );
  }
};

export default AddingNewData;
