import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const Layout = () => {
  const navigate = useNavigate();
  const isAuth = localStorage.getItem("isAuth");
  const [seconds, setSeconds] = useState(0);
  const [isStay, setIsStay] = useState(false);
  const [timeOut, setTimeOut] = useState(false);

  const checkForActivity = (sec) => {
    const expireTime = localStorage.getItem("expireTime");
    if (timeOut) {
      setSeconds(sec - 1);
      if (seconds === 0) {
        localStorage.removeItem("isAuth");
        navigate("/auth");
      }
    } else if (expireTime < Date.now()) {
      setIsStay(true);
      setSeconds(60 * 1);
      setTimeOut(true);
    }
  };

  const updateExpireTime = () => {
    const expireTime = Date.now() + 1000 * 60 * 2; //1000 * 60 * 3
    localStorage.setItem("expireTime", expireTime);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkForActivity(seconds);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, timeOut]);

  useEffect(() => {
    updateExpireTime();

    window.addEventListener("click", updateExpireTime);
    window.addEventListener("keypress", updateExpireTime);
    window.addEventListener("scroll", updateExpireTime);
    window.addEventListener("mousemove", updateExpireTime);

    return () => {
      window.removeEventListener("click", updateExpireTime);
      window.removeEventListener("keypress", updateExpireTime);
      window.removeEventListener("scroll", updateExpireTime);
      window.removeEventListener("mousemove", updateExpireTime);
    };
  }, []);

  return (
    <>
      <Outlet />

      {isAuth && isStay && (
        <>
          <div className="modal">
            <div className="modal-border">
              <div className="modal-header">modal header</div>
              <div className="timer">{seconds}</div>
              <div className="modal-bottom">
                <div
                  className="stay-button"
                  onClick={() => {
                    setIsStay(false);
                    setSeconds(5);
                    setTimeOut(false);
                  }}
                >
                  Stay login
                </div>
                <div
                  className="stay-button"
                  onClick={() => {
                    setIsStay(false);
                    setTimeOut(false);
                    localStorage.removeItem("isAuth");
                    navigate("/auth");
                  }}
                >
                  LogOut
                </div>
              </div>
            </div>
          </div>
          <div className="modal-bigger" />
        </>
      )}
    </>
  );
};

export default Layout;
