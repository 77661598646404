import { useNavigate, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "./LoginPage.css";
import ky from "ky";
const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState([]);
  const [password, setPassword] = useState([]);
  const [value, setValue] = useState([]);

  const apiUrl = "https://6426d11d556bad2a5b58c7dc.mockapi.io/MealsApi/users";
  const getData = async () => {
    let result = await ky(apiUrl).json();
    setValue(result);
  };
  useEffect(() => {
    getData();
  }, []);
  const isAuth = localStorage.getItem("isAuth");

  const loginHandler = (e) => {
    value.map((item) => {
      if (item.email === email && item.password === password) {
        localStorage.setItem("isAuth", true);
      }
    });

    navigate("/home");
    getData();
  };

  if (isAuth) {
    return <Navigate replace to="/home" />;
  } else {
    return (
      <main className="bgimage">
        <section>
          <form className="expenses" onSubmit={loginHandler}>
            <div>
              {/* <label className="inputs" htmlFor="email">Email</label> */}
              <input
                placeholder="Enter Email"
                type="email"
                name="email"
                id="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              {/* <label className="inputs"  htmlFor="password">Password</label> */}
              <input
                placeholder="Enter Password"
                type="password"
                name="password"
                id="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button className="bttn">Login</button>
          </form>
        </section>
      </main>
    );
  }
};

export default LoginPage;
