import { useState, useEffect } from "react";
import ky from "ky";
import "./EmployeeTable.css";
import { useNavigate, Navigate } from "react-router-dom";

const MyApiComponent = () => {
  const isAuth = localStorage.getItem("isAuth");
  const [value, setValue] = useState([]);
  const navigate = useNavigate();

  const apiUrl = "https://6426d11d556bad2a5b58c7dc.mockapi.io/MealsApi/users";
  const getData = async () => {
    let result = await ky(apiUrl).json();
    setValue(result);
  };

  useEffect(() => {
    getData();
  }, []);

  const addDataHandler = () => {
    navigate("/add");
  };
  const logoutHandler = () => {
    localStorage.removeItem("isAuth");
    navigate("/auth");
  };

  const onDelete = async (aaaa) => {
    try {
      await ky.delete(`${apiUrl}/${aaaa}`, {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
      });
    } catch (err) {
      console.log(err);
    }
    getData();
  };

  const confirmEditing = (id) => {
    const filtered = value.find((x) => x.id === id);
    navigate(`/edit/${id}`, {
      state: {
        filtered: filtered,
      },
    });
  };
  if (!isAuth) {
    return <Navigate replace to="/auth" />;
  } else {
    return (
      <div>
        {
          <div>
            <table className="employee">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th>User Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Company Id</th>
                  <th>Password</th>
                  <th>Modify</th>
                </tr>
              </thead>
              <tbody>
                {value.map((val) => (
                  <tr key={val.id}>
                    <td>{val.id}</td>
                    <td>{val.name}</td>
                    <td>{val.username}</td>
                    <td>{val.email}</td>
                    <td>{val.phone}</td>
                    <td>{val.companiesId}</td>
                    <td>{val.password}</td>
                    <td>
                      <button
                        className="button"
                        onClick={() => confirmEditing(val.id)}
                      >
                        Edit
                      </button>
                      <button
                        className="button"
                        onClick={() => onDelete(val.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button className="btn" onClick={addDataHandler}>
              Add new data
            </button>
            <button className="btn" onClick={logoutHandler}>
              Logout
            </button>
          </div>
        }
      </div>
    );
  }
};

export default MyApiComponent;
